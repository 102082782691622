* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-container {
  background: transparent;
  padding: 40px;
  border-radius: 10px;
  max-width: 450px;
  text-align: center;
  position: relative;
  display: grid;
}

.login-container h1 {
  font-size: 28px;
  margin-bottom: 24px;
  color: green;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.login-container label {
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
  text-align: left;
  color: black;
}

.login-container input {
  width: 100%;
  padding: 14px;
  margin-bottom: 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.login-container input:focus {
  outline: none;
  border: 2px solid green;
}

.login-container button {
  width: 100%;
  padding: 14px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  opacity: 0.8;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.login-container button:hover {
  background-color: green;
  opacity: 1;
}

.login-container p {
  margin-top: 16px;
  color: black;
}

.login-container a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-container a:hover {
  color: green;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.login-header {
  margin-bottom: 100px;
}

.login-body {
  margin: 24px;
}
