.main {
  position: relative;
  display: grid;
  gap: 1rem;
  justify-content: center;
  place-content: center;
  justify-items: center;
  overflow: auto;
  flex-grow: 1;
  background-image: url(/images/background.webp);
  background-size: cover;
  background-position: center;
}

.tabContainer {
  width: 100%;
  max-width: 500px;
  padding-inline: 2rem;
}

.tabSelect {
  display: flex;
  justify-content: stretch;
  gap: 0.125rem;
  padding: 0.125rem;
  margin: 0 auto 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.tabSelect > * {
  width: 100%;
}

.tabContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
  background-color: transparent;
  border-radius: 0.5rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  font: 600;
}

.inputField {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.inputField:focus {
  outline: none;
}
